.box {
  overflow: hidden;
  background-color: #ffffffaa;

  border: 1px solid #242e4b;

  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
