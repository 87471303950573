@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Navbar */
.MainNav {
  overflow: hidden;
  backdrop-filter: blur(6px);
  background-color: #ffffff99;
  z-index: 100;

  position: fixed;
  top: 0;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  animation: fadeIn 1s forwards;
}

/* Navbar logo */
.MainNav img.navLogo {
  height: 60px;
  pointer-events: none;
  float: left;
  padding: 14px;
}

/* Navbar title section */
.MainNav .sessionHeader {
  text-align: center;
}

/* Navbar title */
.MainNav h1.navSession {
  padding: 0.2rem 0.5rem;
  margin: 0;
}

/* Navbar sessions link */
.MainNav .navSessionList {
  color: #242e4b;
}

/* Navbar buttons */
.MainNav button.navButton {
  background: #fff;
  background: radial-gradient(ellipse at top left, #ffffffcc, #ffffff77);
  backdrop-filter: blur(6px);
  padding: 0.8rem 1.6rem;
  /*optional*/
  font: 14px 'Century Gothic', Futura, sans-serif;
  /*input has OS specific font-family*/
  color: #000;
  border: 2px solid #242e4b;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 1rem;
}
