@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  48% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Page */
.Main {
  animation: fadeIn 1s forwards;
}

/* Environment image */
.MainBackground {
  width: 100vw;
  height: 100vh;
  position: fixed;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #fff;
}

.MainBackground.Overlay {
  animation-name: fadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 60s;
  animation-direction: alternate;
  background-color: #fff;
}

/* Content */
.MainContent {
  padding: calc(88px + 1rem) 1rem 1rem 1rem;
  min-height: calc(100vh - 88px - 2rem);

  animation: fadeIn 1.5s forwards;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
