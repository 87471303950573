$goldBold: #ffc107;
$goldPale: #ffecb3;

$midnightBlue-0: #242e4b;
$midnightBlue-l2: #35436e;
$midnightBlue-l4: #42538a;
$midnightBlue-l6: #5064a5;
$midnightBlue-l20: #e3e7f2;

$primaryButtonBg: $midnightBlue-0;
$primaryButtonBgHov: $midnightBlue-l2;
$primaryButtonFg: white;
$primaryButtonFgHov: $midnightBlue-l20;

$secondaryButtonBg: white;
$secondaryButtonBgHov: $midnightBlue-l20;
$secondaryButtonFg: $midnightBlue-0;
$secondaryButtonFgHov: $midnightBlue-l2;

$errorSurface: #ff9999;
$errorHighlight: #cc6666;
$errorText: #993333;

$enabledSurface: #ffffff;
$enabledHighlight: #cccccc;
$enabledText: #111111;

$disabledSurface: #eeeeee;
$disabledActionSurface: #cccccc;
$disabledHighlight: #aaaaaa;
$disabledText: #333333;
