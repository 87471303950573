.JourneyView {
}

.Intro {
  font-size: 80%;
  margin-bottom: 1rem;
}

.Question {
  font-size: 200%;
  margin-bottom: 1rem;
}

.Conclusion {
  font-size: 200%;
  color: #242e4b;
  margin-bottom: 1rem;
  white-space: pre-wrap;
}

.Answers a {
  display: block;
  font-size: 150%;
  margin-bottom: 1rem;
  border: 2px solid #242e4b;
  background-color: #fff;
  cursor: pointer;
  color: black;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
}

.Answers a:link,
a:visited {
  background-color: #ccc;
  color: black;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
}

.Answers a:hover,
a:active {
  background-color: #242e4b;
  color: white;
}

img.main {
  max-width: 400px;
  max-height: 400px;
}

img.nodeImg {
  max-width: 400px;
  max-height: 400px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img.edgeImg {
  max-width: 150px;
  max-height: 150px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
