.ImagePreview {
  position: relative;
  text-align: center;
}

.ImagePreview > img {
  width: 100%;
  max-width: 400px;
}

/* Fullscreen enabled by props */
.ImagePreviewFullscreen > img {
  width: 100vw;
  height: 100vh;
}
