@import './variables.scss';

.btn {
  font: inherit;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  margin: 0 1rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: $primaryButtonBg;
  border: 1px solid $primaryButtonBg;
  color: $primaryButtonFg;
  text-decoration: none;
}

.btn:hover,
.btn:active {
  background-color: $primaryButtonBgHov;
  border-color: $primaryButtonBgHov;
  color: $primaryButtonFgHov;
}

.btn:disabled,
.btn:disabled:hover,
.btn:disabled:active {
  background-color: $disabledActionSurface;
  border-color: $disabledHighlight;
  color: $disabledText;
  cursor: not-allowed;
}

.btnAlt {
  background-color: $secondaryButtonBg;
  color: $secondaryButtonFg;
}

.btnAlt:hover,
.btnAlt:active {
  background-color: $secondaryButtonBgHov;
  color: $secondaryButtonFgHov;
}

.btnAlt:disabled,
.btnAlt:disabled:hover,
.btnAlt:disabled:active {
  background-color: $disabledActionSurface;
  border-color: $disabledHighlight;
  color: $disabledText;
  cursor: not-allowed;
}
