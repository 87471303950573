@import '../../styles/buttons.scss';

.breadcrumbs {
  font-size: 80%;
  font-weight: 400;
  color: #aaaaaa;
}
.breadcrumbs a,
.breadcrumbs a:visited,
.breadcrumbs a:hover {
  color: #777777;
}
