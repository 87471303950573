.SplashScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  font-size: calc(10px + 2vmin);

  background-color: white;
  color: black;
}

.SplashScreen .entryLogo {
  height: 40vmin;
  pointer-events: none;
  animation: fadeOut 1s forwards;
  animation-delay: 10s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
