@import './variables.scss';
@import './buttons.scss';

.form {
  padding: 1rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.errorText {
  color: $errorText;
  font-weight: 400;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  background-color: $enabledSurface;
  border: 1px solid $enabledHighlight;
  color: $enabledText;
  padding: 0.25rem;
  width: 100%;
}

.control input:disabled,
.control textarea:disabled {
  background-color: $disabledSurface;
  border: 1px solid $disabledHighlight;
  color: $disabledText;
}

.control.error input,
.control.error textarea {
  background-color: $errorSurface;
  border: 1px solid $errorHighlight;
  color: $errorText;
}

.actions {
  margin-top: 1rem;
  text-align: right;
}
