@import '../../styles/buttons.scss';

.backdrop {
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.modal {
  position: fixed;
  z-index: 1001;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 1rem;
  text-align: center;
  width: 30rem;
  top: 20vh;
  left: calc(50% - 15rem);
}

.actions {
  margin-top: 2rem;
  text-align: center;
}
