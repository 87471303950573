.ImageInputContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.CameraIcon {
  font-size: 600% !important;
  width: 100% !important;
}

.ImgCamera > div > img,
.ImgCamera > div > video {
  width: 100%;
}

.Hint {
  text-align: center;
  font-size: 80%;
}

.Skip {
  text-align: center;
  font-size: 60%;
}
